$red-color: #C10C1A;

body {
  background: #000000;
  font-family: 'Rubik', sans-serif;
  color: #ffffff;
  line-height: 1.3;
  font-size: 16px;
  font-weight: 300;
}

*:focus {
  outline: none !important;
  box-shadow: none !important;
}

a {
  color: #FFFFFF;
  text-decoration: underline;

  &:hover {
    color: #FFFFFF;
    text-decoration: none;
  }
}

p {
  line-height: 1.4rem;
}

.paragraph-opacity {
  color: rgba(255, 255, 255, 0.7);
}

.list-unstyled {
  li {
    padding-left: 20px;
    position: relative;
    margin-bottom: 15px;

    &:before {
      content: "\f111";
      font-weight: 900;
      font-family: "Font Awesome 5 Free";
      font-size: .75em;
      color: $red-color;
      filter: blur(3px);
      position: absolute;
      left: 0;
      top: 2px;
    }
  }
}

h1, .h1 {
  font-weight: bold;
  font-size: 36px;
  text-transform: uppercase;
  color: #FFFFFF;
  text-shadow: 0 0 6px rgba(255, 255, 255, 0.65);
  @media (min-width: 576px) {
    font-size: 50px;
  }
}

h2, .h2 {
  font-weight: bold;
  font-size: 34px;
  text-transform: uppercase;
  color: #FFFFFF;
  text-shadow: 0 0 15px rgba(255, 255, 255, 0.45);
  @media (min-width: 576px) {
    font-size: 42px;
  }
}

h3, .h3 {
  font-size: 28px;
  font-weight: bold;
}

h4, .h4 {
  font-size: 28px;
  font-weight: bold;
  text-shadow: 0 0 15px rgba(255, 255, 255, 0.45);
}

h5, .h5 {
  font-size: 18px;
  font-weight: normal;
}

h6, .h6 {
  font-size: 21px;
  font-weight: normal;
}

.text-red {
  color: $red-color;
  &:hover {
    color: $red-color;
  }
}

header {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
  padding: 10px 0;
  background: #000000;
  @media (min-width: 576px) {
    top: 0;
    bottom: unset;
    background: transparent;
    padding-top: 28px;
    padding-bottom: 10px;
  }

  &.black {
    background: #000000;
  }
}

.header-logo {
  width: 80px;

  &:hover {
    text-decoration: none;
  }

  @media (min-width: 1200px) {
    width: auto;
  }
}

.header-phone {
  font-weight: bold;
  font-size: 18px;
  color: $red-color;
  display: inline-block;
  text-decoration: none;
  @media (min-width: 576px) {
    font-size: 16px;
  }

  &:hover {
    color: $red-color;
    text-decoration: none;
  }
}

.header-menu {
  .nav-link {
    font-weight: bold;
    font-size: 21px;
    text-transform: uppercase;
    color: #FFFFFF;
    padding-top: 0;
    padding-bottom: 0;
    transition: 0.3s;
    text-decoration: none;

    &:hover {
      color: $red-color;
      text-shadow: 0 0 5px $red-color;
    }
  }

  .nav-item {
    position: relative;
  }

  .nav-item:not(:last-of-type):after {
    content: '';
    position: absolute;
    top: 6px;
    right: 0;
    background: linear-gradient(to bottom, $red-color 50%, rgba(195, 20, 37, 0));
    height: 21px;
    width: 1px;
  }
}

.btn-red {
  font-weight: bold;
  font-size: 16px;
  text-transform: uppercase;
  color: #FFFFFF;
  padding: 10px;
  width: auto;
  background: transparent;
  box-shadow: 0 0 15px rgba(226, 25, 25, 0.45);
  line-height: 1.3;
  border: 1px solid $red-color;
  border-radius: 0;
  transition: 0.3s;
  @media (min-width: 576px) {
    min-width: 146px;
    font-size: 21px;
    padding: 8px 12px;
  }

  &_mobile {
    width: auto;
    font-size: 14px;
    padding: 8px 15px;
  }

  &:hover {
    color: #FFFFFF;
    box-shadow: 0 0 15px $red-color;
    background: $red-color;
  }
}

.outer-wrapper {
  position: relative;
  height: 100vh;
  min-height: 400px;
  z-index: 30;
  background: #000000;

  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100px;
    background: linear-gradient(to top, #000 50%, rgba(0, 0, 0, 0));
    z-index: 25;
  }

  @media (min-width: 768px) {
    height: 500px;
  }
}

.header-content {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  z-index: 26;

  .header-logo {
    padding-bottom: 100px;
  }

  @media (min-width: 576px) {
    padding-top: 200px;
  }
}

#canvas01 {
  position: relative;
  width: 100%;
  height: 100%;
}

#canvas02 {
  width: 100%;
  top: 0;
  bottom: 0;
  overflow: hidden;
  position: fixed !important;
}

.wrapper {
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 500px;
    background: linear-gradient(to bottom, #000 50%, rgba(0, 0, 0, 0));
    z-index: 25;
  }

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 500px;
    background: linear-gradient(to top, #000 50%, rgba(0, 0, 0, 0));
    z-index: 25;
  }
}

.wrapper-content {
  position: relative;
  z-index: 30;
}

@keyframes gradient {
  0% {
    filter: blur(0);
  }
  50% {
    filter: blur(2px);
  }
  100% {
    filter: blur(0);
  }
}

.socials-icon {
  font-size: 38px;
  color: #FFFFFF;
  width: 65px;
  height: 65px;
  border-radius: 50%;
  background: radial-gradient(circle, $red-color, #000 80%);
  display: inline-block;
  text-align: center;
  line-height: 65px;
  position: absolute;

  filter: blur(0);

  animation-name: gradient;
  animation-timing-function: linear;
  animation-duration: 2s;
  animation-iteration-count: infinite;

  &:nth-child(2) {
    animation-delay: 1s;
  }

  &:nth-child(3) {
    animation-delay: 2s;
  }

  &:nth-child(4) {
    animation-delay: 3s;
  }

  &:nth-child(5) {
    animation-delay: 4s;
  }

  &:nth-child(6) {
    animation-delay: 5s;
  }

  &:nth-child(7) {
    animation-delay: 6s;
  }

  &_not-bg {
    background: transparent;
    width: auto;
    height: auto;
    line-height: inherit;
  }

  &_not-link {
    line-height: unset;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &:hover {
    color: #FFFFFF;
  }
}

.socials-icon_instagram {
  right: 0;
  top: 30px;
}

.socials-icon_vk {
  top: 5px;
  left: 180px;
}

.socials-icon_ok {
  top: 100px;
  left: 195px;
}

.socials-icon_facebook {
  top: 130px;
  right: 80px;
}

.socials-icon_thumbs {
  top: 130px;
  right: 0;
}

.socials-icon_camera {
  top: 0;
  right: 100px;
}

.socials-icon_scroll {
  top: 45px;
  right: 100px;
}

.socials-icon_pencil {
  top: 65px;
  right: 150px;
}

.socials-icon_head {
  bottom: 15px;
  left: 120px;
}

.dot {
  background: radial-gradient(circle, $red-color, #000 80%);
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: block;
  position: absolute;
}

.dot01 {
  bottom: 25px;
  left: 50px;
  width: 15px;
  height: 15px;
}

.dot02 {
  bottom: 90px;
  left: 85px;
  width: 30px;
  height: 30px;
}

.dot03 {
  bottom: 122px;
  left: 156px;
}

.dot04 {
  bottom: -10px;
  left: 205px;
  width: 32px;
  height: 32px;
}

.dot05 {
  bottom: 20px;
  left: 275px;
}

.dot06 {
  top: 40px;
  right: 160px;
}

.dot07 {
  top: 5px;
  right: 60px;
}

.dot08 {
  top: 120px;
  right: 35px;
}

.services-block {
  border: 2px solid $red-color;
  border-image: linear-gradient(to right top, $red-color, #000);
  border-image-slice: 1;
  padding: 34px 24px;
  @media (min-width: 576px) {
    padding: 34px 46px;
  }
}

.tariffs-block {
  border: 2px solid $red-color;
  border-image: linear-gradient(to bottom, $red-color, #000);
  border-image-slice: 1;
  padding: 34px 24px;
  @media (min-width: 576px) {
    padding: 40px 35px;
  }
}

.service-block {
  border: 0;
  border-left: 1px solid $red-color;
  border-image: linear-gradient(to bottom, $red-color, #000);
  border-image-slice: 1;
  padding-left: 10px;
}

.service-icon {
  max-width: 65px;
  flex: 0 0 65px;
  margin-right: 14px;

  &_sm {
    max-width: 42px;
    flex: 0 0 42px;
    margin-right: 10px;
  }
}

hr {
  border-top: 1px solid #FFFFFF;
}

.about-block {
  border: 2px solid #fff;
  border-image: linear-gradient(to right top, #fff, rgba(255, 255, 255, 0));
  border-image-slice: 1;
  padding: 28px;
  height: 100%;

  &__title {
    border-bottom: 1px solid #FFFFFF;
    padding-bottom: 22px;
    margin-bottom: 22px;
  }
}

.icon-content {
  position: relative;

  &:before {
    content: '';
    position: absolute;
    border: 1px dashed #FFFFFF;
    width: calc(100% - 10px);
    top: 10px;
    right: 5px;
    z-index: 1;
  }
}

.line-title {
  display: inline-block;
  background: #000000;
  position: relative;
  z-index: 2;
  padding-right: 6px;
}

.brief-link {
  font-weight: bold;
  font-size: 21px;
  text-decoration: underline;
  text-transform: uppercase;
  color: #FFFFFF;
}

.form-control {
  background: transparent;
  border-top: 0 !important;
  border-left: 0 !important;
  border-right: 0 !important;
  border-bottom: 1px solid rgba(255, 255, 255, 0.65);
  border-image: linear-gradient(to right, rgba(255, 255, 255, 0.65), rgba(255, 255, 255, 0));
  border-image-slice: 1;
  padding: 10px 15px 15px 0;
  height: auto;
  color: #FFFFFF;
  font-family: 'Rubik', sans-serif;
  font-weight: normal;
  font-size: 16px;

  &:focus {
    background: transparent;
    color: #FFFFFF;
  }
}

.form-control::-webkit-input-placeholder {
  font-family: 'Rubik', sans-serif;
  font-weight: normal;
  font-size: 16px;
  text-transform: uppercase;
  color: #FFFFFF;
}

.form-control::-moz-placeholder {
  font-family: 'Rubik', sans-serif;
  font-weight: normal;
  font-size: 16px;
  text-transform: uppercase;
  color: #FFFFFF;
}

.form-control:-moz-placeholder {
  font-family: 'Rubik', sans-serif;
  font-weight: normal;
  font-size: 16px;
  text-transform: uppercase;
  color: #FFFFFF;
}

.form-control:-ms-input-placeholder {
  font-family: 'Rubik', sans-serif;
  font-weight: normal;
  font-size: 16px;
  text-transform: uppercase;
  color: #FFFFFF;
}

.form-control:focus::-webkit-input-placeholder {
  opacity: 0;
}

.form-control:focus::-moz-placeholder {
  opacity: 0;
}

.form-control:focus:-moz-placeholder {
  opacity: 0;
}

.form-control:focus:-ms-input-placeholder {
  opacity: 0;
}

.custom-control {
  padding-left: 30px;
}

.custom-control-label {
  font-size: 11px;
  line-height: 21px;
  display: block;

  &:before {
    position: absolute;
    top: 0;
    width: 21px;
    height: 21px;
    background-color: transparent;
    border: #FFFFFF solid 1px;
    border-radius: 0 !important;
    left: -30px;
  }

  &:after {
    background-size: unset;
    width: 30px;
    height: 30px;
    top: -7px;
    left: -30px;
  }
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3Csvg width='28' height='30' viewBox='0 0 28 30' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg filter='url(%23filter0_d)'%3E%3Cpath d='M4.5 13.5L9.5 20.5L23.5 1' stroke='white'/%3E%3C/g%3E%3Cdefs%3E%3Cfilter id='filter0_d' x='0.0931396' y='0.708374' width='27.813' height='28.6504' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB'%3E%3CfeFlood flood-opacity='0' result='BackgroundImageFix'/%3E%3CfeColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'/%3E%3CfeOffset dy='4'/%3E%3CfeGaussianBlur stdDeviation='2'/%3E%3CfeColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0'/%3E%3CfeBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow'/%3E%3CfeBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow' result='shape'/%3E%3C/filter%3E%3C/defs%3E%3C/svg%3E");
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #FFFFFF;
  background-color: transparent;
}

.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: none !important;
}

.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: unset;
}

.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: #000000;
  border-color: #FFFFFF;
}

.file-input {
  width: 1px;
  height: 1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.file-button {
  display: inline-block;
  font-size: 18px;
  margin-right: 15px;
  color: #FFFFFF;
  cursor: pointer;
}

.file-text {
  font-family: 'Rubik', sans-serif;
  font-weight: normal;
  font-size: 16px;
  text-transform: uppercase;
  color: #FFFFFF;
  cursor: pointer;
}

.file-comment {
  font-size: 10px;
}

.for-result {
  .alert {
    border-radius: 0;
  }

  .alert-success {
    color: #FFFFFF;
    background: transparent;
    border-color: #FFFFFF;
  }

  .alert-danger {
    color: $red-color;
    background: transparent;
    border-color: $red-color;
  }
}

.create-company {
  color: #777;
}

footer {
  background: #000000;
  position: relative;
  padding-bottom: 76px;
}

.footer-social {
  font-size: 28px;
  color: $red-color;
  transition: 0.3s;
  text-decoration: none;

  &:hover {
    color: $red-color;
    text-shadow: 0 0 5px $red-color;
    text-decoration: none;
  }
}

.footer-contact {
  color: #fff;
  text-transform: uppercase;
  display: inline-block;
  text-decoration: none;

  i {
    font-size: 28px;
  }

  &:hover {
    color: $red-color;
    text-decoration: none;
  }
}